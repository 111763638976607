import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DokumentEigenschaftenBearbeitenModel } from '@dworkflow/shared/model/dokumente/dokument-eigenschaften-bearbeiten.model';
import { DokumentEigenschaftenUpdateModel } from '@dworkflow/shared/model/dokumente/dokument-eigenschaften-update.model';
import { DokumenteEinstellungenUpdateModel } from '@dworkflow/shared/model/dokumente/dokumente-einstellungen-update.model';
import { DokumenteEinstellungenModel } from '@dworkflow/shared/model/dokumente/dokumente-einstellungen.model';
import { DokumentenAenderungModel } from '@dworkflow/shared/model/dokumente/dokumenten-aenderung.model';
import { DokumentenListeModel } from '@dworkflow/shared/model/dokumente/dokumenten-liste.model';
import { FormularDatenModel } from '@dworkflow/shared/model/dokumente/formular-daten.model';
import { Formular } from '@dworkflow/shared/model/dokumente/formular.model';
import { FormulareMitFormularDatenModel } from '@dworkflow/shared/model/dokumente/formulare-mit-formulardaten.model';
import { PapierVerfuegungErstellenModel } from '@dworkflow/shared/model/dokumente/papierverfuegung-erstellen.model';
import { VorlagenDokumentModel } from '@dworkflow/shared/model/dokumente/vorlagen-dokument.model';
import { WorkflowDokumentModel } from '@dworkflow/shared/model/dokumente/workflow-dokument.model';
import { GuidHelper, guid } from '@dworkflow/shared/utility/guid';
import * as DokumentenActions from '@dworkflow/state/dokumente.actions';
import * as fromState from '@dworkflow/state/dokumente.state';
import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantFormularBerechtigungenModel } from '../model/dokumente/tenant-formular-berechtigungen.model';
import { WriteFormularDatenModel } from '../model/dokumente/write-formulardaten.modelt';
import { FormularschrittAbschliessenModel } from '../model/formularschritt-abschliessen.model';
/**
 * Interner Service zur DokumenteApi
 */

@Injectable({
  providedIn: 'root',
})
export class DokumenteServiceInternal {
  http = inject(HttpClient);
  store = inject(Store);
  constructor() {}

  public uploadFile(
    dokument: WorkflowDokumentModel,
    dokumentenFile: fromState.DokumentFile
  ): Observable<HttpEvent<unknown>> {
    // Hier wird ein `multipart/form-data` Request gebaut. Im Vergleich zu einem
    // `application/x-www-form-urlencoded` Request, kann dieser auch binäre Daten
    // transportieren, weshalb es bei einem File-Upload passend ist.
    //
    // Wichtig ist, dass hier der `Content-Type` Header nicht manuell gesetzt wird,
    // weil der Browser sich darum kümmern wird. Wenn das doch gemacht wird, ist der
    // Header Wert nicht gültig:
    //
    // => manuell gesetzt:
    // `Content-Type: multipart/form-data;`
    //
    // => vom Browser gesetzt:
    // `Content-Type: multipart/form-data; boundary=-----WebKitFormBoundaryKAhJJhvUsQdkGOzEkz`
    //
    // Dies führt zu dem Fehler vom Server "Missing Content-Type boundary".
    const formData = new FormData();

    formData.append('DokumentenId', dokument.dokumentenId);
    formData.append('Name', dokument.name);
    formData.append('Dokument', dokumentenFile.file);
    formData.append('Position', (dokument.neuePosition ?? dokument.position ?? 0).toString());

    let url = environment.dokumente.dokumentEndpoint;

    if (dokument.objectStorageKey) {
      url = url + '/vorlagenDokument';
      formData.append('VorlagenDokumentenId', dokument.vorlagenDokumentId);
    }
    const headers = new HttpHeaders({
      'dWorkflow-Tenant-Id': dokument.tenantId.toString(),
      'ngsw-bypass': '',
    });

    const req = this.http.post<HttpEvent<WorkflowDokumentModel>>(
      `${url}/${dokument.workflowId}?bypass=true`,
      formData,
      {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      }
    );
    return req;
  }

  public getEinstellungen(): Observable<DokumenteEinstellungenModel> {
    return this.http.get<DokumenteEinstellungenModel>(environment.dokumente.einstellungenEndpoint);
  }

  public updateEinstellungen(
    einstellungen: DokumenteEinstellungenUpdateModel
  ): Observable<DokumenteEinstellungenModel> {
    return this.http.put<DokumenteEinstellungenModel>(
      environment.dokumente.einstellungenEndpoint,
      einstellungen
    );
  }

  public getDokumente(
    workflowId: guid,
    timestampInTicks: string
  ): Observable<DokumentenListeModel> {
    let params = new HttpParams();
    if (timestampInTicks) {
      params = params.set('timestamp', timestampInTicks);
    }
    return this.http.post<DokumentenListeModel>(
      `${environment.dokumente.dokumentEndpoint}/get/${workflowId}`,
      {},
      {
        params,
      }
    );
  }

  public getDokumenteByWorkflowId(workflowId: guid): Observable<WorkflowDokumentModel[]> {
    return this.store.select(fromState.selectDokumenteByWorkflowId(workflowId));
  }

  public addDokumentToUpload(
    workflowId: guid,
    file: File,
    currentUserId: number,
    uploadImmediately: boolean,
    tenantId: number,
    fileNameOverride?: string,
    overwrittenDokumentenId?: guid,
    position?: number
  ): void {
    const dokumentenId = overwrittenDokumentenId ?? GuidHelper.create();
    this.store.dispatch(
      uploadImmediately
        ? DokumentenActions.addQueuedDokument({
            workflowId,
            dokumentenId,
            file,
            currentUserId,
            fileNameOverride,
            tenantId,
          })
        : DokumentenActions.addPendingDokumente({
            vorlagenDokumente: [
              {
                vorlagenDokumentId: null,
                workflowId,
                dokumentenId,
                file,
                currentUserId,
                fileNameOverride,
                tenantId,
                position,
              },
            ],
          })
    );
  }

  public addVorlagenDokumentToUpload(
    vorlagenId: guid,
    file: File,
    currentUserId: number,
    tenantId: number,
    fileNameOverride?: string,
    overwrittenDokumentenId?: guid
  ): void {
    const dokumentenId = overwrittenDokumentenId ?? GuidHelper.create();
    this.store.dispatch(
      DokumentenActions.addPendingVorlagenDokument({
        vorlagenId,
        dokumentenId,
        file,
        currentUserId,
        fileNameOverride,
        tenantId,
      })
    );
  }

  // Dokumente-Eigenschaften
  public sendUpdateUploadedDokumentEigenschaftenRequest(
    eigenschaften: DokumentEigenschaftenBearbeitenModel,
    dokumentenId: guid,
    workflowId: guid
  ): Observable<unknown> {
    return this.http.patch(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/${dokumentenId}`,
      eigenschaften
    );
  }

  public updatePendingDokumentEigenschaften(
    dokumentenId: guid,
    changes: DokumentEigenschaftenUpdateModel,
    workflowId: guid,
    vorlagenId: guid
  ): void {
    this.store.dispatch(
      DokumentenActions.updatePendingDokumentEigenschaften({
        dokumentenId,
        changes,
        workflowId,
        vorlagenId,
      })
    );
  }

  public updateUploadedDokumentEigenschaften(
    dokumentenId: guid,
    workflowId: guid,
    eigenschaften: DokumentEigenschaftenBearbeitenModel
  ): void {
    this.store.dispatch(
      DokumentenActions.updateUploadedDokumentEigenschaften({
        dokumentenId,
        workflowId,
        eigenschaften,
      })
    );
  }

  public dokumentEntsperren(
    dokumentenId: guid,
    workflowId: guid
  ): Observable<WorkflowDokumentModel> {
    return this.http.post<WorkflowDokumentModel>(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/${dokumentenId}/entsperren`,
      {}
    );
  }

  public vorlagenDokumentEntsperren(
    dokumentenId: guid,
    vorlagenId: guid
  ): Observable<VorlagenDokumentModel> {
    return this.http.post<VorlagenDokumentModel>(
      `${environment.dokumente.vorlageEndpoint}/${vorlagenId}/${dokumentenId}/entsperren`,
      {}
    );
  }

  // Dokumentsortierung
  public postDokumentReihenfolgeAktualisieren(
    workflowId: guid,
    dokumenteReihenfolge: guid[]
  ): Observable<WorkflowDokumentModel[]> {
    return this.http.post<WorkflowDokumentModel[]>(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/reihenfolge`,
      dokumenteReihenfolge
    );
  }

  public postVorlagenDokumentReihenfolgeAktualisieren(
    vorlagenId: guid,
    dokumentenIdToPositionMapping: { [id: guid]: number }
  ): Observable<unknown> {
    return this.http.post(
      `${environment.dokumente.vorlageEndpoint}/${vorlagenId}/reihenfolge`,
      dokumentenIdToPositionMapping
    );
  }

  // Papierverfuegung
  public postPapierverfuegung(
    papierVerfuegung: PapierVerfuegungErstellenModel,
    workflowId: guid
  ): Observable<unknown> {
    return this.http.post(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/papierverfuegung`,
      papierVerfuegung
    );
  }

  public loescheDokument(
    dokumentId: guid,
    workflowId: guid,
    kommentar: string
  ): Observable<unknown> {
    return this.http.post(`${environment.dokumente.dokumentEndpoint}/${workflowId}/${dokumentId}`, {
      kommentar,
    });
  }

  public loescheDokumente(
    dokumentenIds: guid[],
    workflowId: guid,
    kommentar: string
  ): Observable<unknown> {
    return this.http.post(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/mehrere-loeschen`,
      { kommentar, dokumentenIds }
    );
  }

  public uploadVorlagenDokument(
    dokument: VorlagenDokumentModel,
    dokumentenFile: fromState.DokumentFile
  ): Observable<HttpEvent<unknown>> {
    // Hier wird ein `multipart/form-data` Request gebaut. Im Vergleich zu einem
    // `application/x-www-form-urlencoded` Request, kann dieser auch binäre Daten
    // transportieren, weshalb es bei einem File-Upload passend ist.
    //
    // Wichtig ist, dass hier der `Content-Type` Header nicht manuell gesetzt wird,
    // weil der Browser sich darum kümmern wird. Wenn das doch gemacht wird, ist der
    // Header Wert nicht gültig:
    //
    // => manuell gesetzt:
    // `Content-Type: multipart/form-data;`
    //
    // => vom Browser gesetzt:
    // `Content-Type: multipart/form-data; boundary=-----WebKitFormBoundaryKAhJJhvUsQdkGOzEkz`
    //
    // Dies führt zu dem Fehler vom Server "Missing Content-Type boundary".
    const formData = new FormData();
    formData.append('DokumentenId', dokument.dokumentenId);
    formData.append('Name', dokument.name);
    formData.append('Dokument', dokumentenFile.file);
    formData.append('Position', (dokument.neuePosition ?? dokument.position ?? 0).toString());

    return this.http.post<HttpEvent<WorkflowDokumentModel>>(
      `${environment.dokumente.vorlageEndpoint}/${dokument.vorlagenId}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  public sendUpdateUploadedVorlagenDokumentEigenschaftenRequest(
    dokumentenId: guid,
    eigenschaften: DokumentEigenschaftenBearbeitenModel
  ): Observable<unknown> {
    return this.http.patch(
      `${environment.dokumente.vorlageEndpoint}/${dokumentenId}`,
      eigenschaften
    );
  }

  public loescheVorlagenDokument(dokumentId: guid, vorlagenId: guid): Observable<unknown> {
    return this.http.delete(`${environment.dokumente.vorlageEndpoint}/${vorlagenId}/${dokumentId}`);
  }

  public getVorlagenDokumente(vorlagenId: guid): Observable<VorlagenDokumentModel[]> {
    return this.http.get<VorlagenDokumentModel[]>(
      `${environment.dokumente.vorlageEndpoint}/vorlage/${vorlagenId}`
    );
  }

  // Dokument Aenderungen
  public getDokumentAenderungen(
    workflowId: guid,
    dokumentId: guid
  ): Observable<DokumentenAenderungModel[]> {
    return this.http.get<DokumentenAenderungModel[]>(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/${dokumentId}/versionen`
    );
  }

  // DokumentenVersion Wiederherstellen
  public dokumentenVersionWiederherstellen(
    workflowId: guid,
    dokumentId: guid,
    version: string
  ): Observable<unknown> {
    return this.http.post(
      `${environment.dokumente.dokumentEndpoint}/${workflowId}/${dokumentId}/version-wiederherstellen?version=${version}`,
      {}
    );
  }

  //Formulare
  getFormulare(): Observable<Formular[]> {
    return this.http.get<Formular[]>(`${environment.dokumente.formularEndpoint}`);
  }

  getTenantFormulareBerechtigungen(): Observable<TenantFormularBerechtigungenModel[]> {
    return this.http.get<TenantFormularBerechtigungenModel[]>(
      `${environment.dokumente.formularEndpoint}/formularBerechtigungen`
    );
  }

  updateTenantFormulareBerechtigungen(
    tenantFormularBerechtigungen: TenantFormularBerechtigungenModel[]
  ): Observable<TenantFormularBerechtigungenModel[]> {
    return this.http.post<TenantFormularBerechtigungenModel[]>(
      `${environment.dokumente.formularEndpoint}/formularBerechtigungen`,
      tenantFormularBerechtigungen
    );
  }

  getFormularById(id: number): Observable<Formular> {
    return this.http.get<Formular>(`${environment.dokumente.formularEndpoint}/${id}`);
  }

  getFormulareByIds(formularIds: number[]): Observable<Formular[]> {
    return forkJoin(formularIds.map(f => this.getFormularById(f)));
  }

  upsertFormular(formular: Formular): Observable<Formular> {
    return this.http.post<Formular>(`${environment.dokumente.formularEndpoint}`, formular);
  }

  loescheFormular(formularId: guid): Observable<unknown> {
    return this.http.delete(`${environment.dokumente.formularEndpoint}/${formularId}`);
  }

  getFormulareWithDatenForWorkflow(workflowId: guid): Observable<FormulareMitFormularDatenModel> {
    return this.http.get<FormulareMitFormularDatenModel>(
      `${environment.dokumente.formularEndpoint}/workflow/${workflowId}`
    );
  }

  loadFormulareWithDatenForVorlage(vorlagenId: guid): Observable<FormulareMitFormularDatenModel> {
    return this.http.get<FormulareMitFormularDatenModel>(
      `${environment.dokumente.formularEndpoint}/vorlage/${vorlagenId}`
    );
  }

  public formularschrittAbschliessen(
    workflowId: guid,
    schrittAbschlussModel: FormularschrittAbschliessenModel
  ): Observable<FormularDatenModel> {
    return this.http.post<FormularDatenModel>(
      `${environment.dokumente.formularEndpoint}/${workflowId}/schrittabschluss`,
      schrittAbschlussModel
    );
  }

  public formularDatenSpeichernFuerWorkflow(
    workflowId: guid,
    daten: WriteFormularDatenModel
  ): Observable<FormularDatenModel> {
    return this.http.post<FormularDatenModel>(
      `${environment.dokumente.formularEndpoint}/formulardaten/workflow/${workflowId}`,
      daten
    );
  }

  public formularDatenSpeichernFuerVorlage(
    vorlagenId: guid,
    daten: WriteFormularDatenModel
  ): Observable<FormularDatenModel> {
    return this.http.post<FormularDatenModel>(
      `${environment.dokumente.formularEndpoint}/formulardaten/vorlage/${vorlagenId}`,
      daten
    );
  }

  istFormularInVerwendung(formularId: guid): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.dokumente.formularEndpoint}/${formularId}/in-verwendung`
    );
  }

  getFormularPreview(
    scribanTemplate: string,
    formlyFormModel: object | string
  ): Observable<string> {
    const formularDaten =
      typeof formlyFormModel === 'string' ? formlyFormModel : JSON.stringify(formlyFormModel);

    return this.http.post(
      `${environment.dokumente.formularEndpoint}/preview`,
      {
        formularTemplate: scribanTemplate,
        formularDaten: formularDaten,
      },
      { responseType: 'text' }
    );
  }

  erstelleWorkflowFormularAlsDokument(
    workflowId: guid,
    formularId: number,
    dokumentEndung: string,
    dokumentenName: string
  ): Observable<WorkflowDokumentModel> {
    return this.http.post<WorkflowDokumentModel>(
      `${environment.dokumente.formularEndpoint}/formulardaten/workflow/${workflowId}/erstelle-datei`,
      {
        dokumentenName: dokumentenName,
        dokumentEndung: dokumentEndung,
        formularId: formularId,
      }
    );
  }

  formularWiederherstellen(formularId: guid, version: string): Observable<Formular> {
    return this.http.post<Formular>(
      `${environment.dokumente.formularEndpoint}/${formularId}/version-wiederherstellen?version=${version}`,
      {}
    );
  }
}
